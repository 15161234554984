<template>
  <main>
    <div class="container">
      <h1>Контакты</h1>
      <hr>
      <h3>Возникли вопросы?</h3>
      <p>Свяжитесь с нами с понедельника по пятницу <b>c 9:00 - до 18:00</b></p>
      <p>
        Служба заботы о клиентах: <b><a href="tel:+375447505037"><nobr>+375 (44) 750-50-37</nobr></a></b><br>
        Email: <b><a href="mailto:support@giftery.by">support@giftery.by</a></b>
      </p>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Contacts',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--inner')
  }
}
</script>
